"use client"

import { useState } from "react"
import clsx from "clsx"

import { FillImage } from "~/components/ui/FillImage"
import { Newsletter } from "~/components/ui/Newsletter"
import PopinHeader from "~/components/ui/PopinHeader"
import RichText from "~/components/ui/RichText"
import type { TPopinNewsletter } from "~/components/globals/PopinNewsletter/_data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type PopinNewsletterComponentProps = {
  onHide?: () => void
  onSuccess?: () => void
  popinNewsletter: NonNullable<TPopinNewsletter>
}

const IMAGE_SIZES = [{ breakpoint: breakpoints.lg, ratio: 3 / 15 }, { ratio: 1 / 2 }]

function PopinNewsletterComponent({ popinNewsletter }: PopinNewsletterComponentProps) {
  const { image, title, subtitle, legals, success } = popinNewsletter
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const t = useTranslate()

  return (
    <div className={css.PopinNewsletterWrapper({ isOpen })} id="popin-newsletter">
      <div className={css.background} id="popin-newsletter-background" onClick={() => setIsOpen(false)} />
      <div className={css.PopinNewsletter}>
        <div className={clsx(css.Wrapper)}>
          {image && (
            <div className={clsx(css.imageContainer, showFrom("tablet", "block"))}>
              <FillImage className={clsx(css.image)} {...image} sizesFromBreakpoints={IMAGE_SIZES} asPlaceholder />
            </div>
          )}
          <div className={clsx(css.content)}>
            <PopinHeader
              className={css.header}
              onRemove={() => setIsOpen(false)}
              headerBackground={false}
              closeButtonId="popin-newsletter-close-button"
            />
            <div className={clsx(css.container)}>
              <RichText
                className={clsx(text({ design: "neue-18-22" }), sprinkles({ textAlign: "center" }))}
                render={title}
                disableStyles
              />
              <RichText
                className={clsx(text({ design: "neue-13", color: "grey-tundora" }), sprinkles({ textAlign: "center" }))}
                render={subtitle}
                disableStyles
              />
              <div>
                <Newsletter
                  className={css.form}
                  onSuccess={() => {
                    setSubmitted(true)
                    setAlreadySubscribed(false)
                  }}
                  onAlreadySubscribed={() => {
                    setAlreadySubscribed(true)
                  }}
                  newsletterLegals={legals}
                />
                {submitted && success && (
                  <RichText className={css.message({ success: true })} disableStyles render={success} />
                )}
                {alreadySubscribed && (
                  <p className={css.message({ success: false })}>{t("newsletter_already_subscribed")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { PopinNewsletterComponent }
